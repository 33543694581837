import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAmazon, faGoodreads } from '@fortawesome/free-brands-svg-icons';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as emptyStar } from '@fortawesome/free-regular-svg-icons';

import './book-review.scss';

export default class BookReview extends Component {

  getIcon = (url) => {
    if(url.includes('amazon')) {
      return faAmazon;
    }
    else if(url.includes('goodreads')) {
      return faGoodreads;
    }
  }

  getStars = (rating) => (
    <div className="BookReview-rating">
      { Array.from({ length: rating }, (v, i) => <FontAwesomeIcon icon={ solidStar } key={ `full-star-${i}` } />) }
      { Array.from({ length: 5 - rating }, (v, i) => <FontAwesomeIcon icon={ emptyStar } key={ `empty-star-${i}` } />) }
    </div>
  );

  render() {
    const { description, author, url = '', rating } = this.props;

    return (
      <div className={ `BookReview` }>
        <div className="BookReview-quote" dangerouslySetInnerHTML={{__html: description }}>
        </div>
        { this.getStars(rating) }
        <span className="BookReview-author">
          - { author }
          <a href={ url } className="BookReview-url">
            <FontAwesomeIcon icon={ this.getIcon(url) } />
          </a></span>
      </div>
    );
  }
}
