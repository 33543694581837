import React, { Component } from 'react';
import PageContent from '../page-content/page-content';

import './page-section-group.scss';

export default class PageSectionGroup extends Component {

  getModifiers = () => {
    const { background, hasNeighbours, settings } = this.props;

    let modifiers = [];

    if(background) {
      modifiers.push("PageSectionGroup-with-background");
    }

    if(hasNeighbours) {
      modifiers.push("has-more-neighbours");
    }

    if(!settings.showPostDetails) {
      modifiers.push("PageSectionGroup-hide-post-details");
    }

    if(settings.hideTitle) {
      modifiers.push("PageSectionGroup-hide-title");
    }

    return modifiers.join(" ")
  };

  render() {
    const { background, hook, title, children } = this.props;

    return (
      <div className={ `PageSectionGroup ${ this.getModifiers() }` }>
        {background &&
        <div className="PageSectionGroup-background"
             style={{ backgroundImage: `url(${background.url})` }}>
          <span dangerouslySetInnerHTML={{__html: background.description}}></span>
        </div>}
        <div className="PageSectionGroup-content-wrapper">
          <PageContent>
            <h1 className="PageSectionGroup-title">
              <a className="hook-link" name={hook}>{title}</a>
            </h1>
          </PageContent>
          { children }
        </div>
      </div>
    );
  }
}
