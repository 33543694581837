import React, {Component} from 'react';
import { Image } from 'react-bootstrap';

import './author-info.scss';

export default class AuthorInfo extends Component {

  getSize = () => {
    const { size } = this.props;

    switch(size) {
      case 'sm': return 24;
      case 'lg': return 96;
      default: return 48;
    }
  };

  render() {
    const { author, date, size, alignment } = this.props;

    return (
      <div className={ `AuthorInfo ${size || 'md'} ${alignment || 'left'}-aligned` }>
        <Image src={ author.images[this.getSize()] } roundedCircle />
        <a href={ author.url } className="AuthorInfo-name">{ author.name }</a>
        <span className="AuthorInfo-date">{ date }</span>
      </div>
    );
  }
}
