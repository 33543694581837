import React, {Component} from 'react';
import './page-content.scss';

export default class PageContent extends Component {
  render() {
    const { children } = this.props;

    return (
      <div className="PageContent">
        { children }
      </div>
    );
  }
}
