import axios from 'axios';

const BASE_URL = "https://jakobaevarsson.com";
const ADMIN_URL = BASE_URL + "/admin/wp-json/";
const V2_URL = ADMIN_URL + "wp/v2";
const CUSTOM_URL = ADMIN_URL + "custom/v1";

export function getData() {
  return axios.get(CUSTOM_URL + "/init");
}

export function getMedia() {
  return axios.get(V2_URL + "/media");
}

export function getBooks() {
  return axios.get(V2_URL + "/books?_fields=author,id,content,excerpt,title,date_gmt,featured_media,meta,slug");
}

export function getFAQ() {
  return axios.get(V2_URL + "/faq?_fields=author,id,content,excerpt,title,date_gmt,featured_media,meta,slug");
}

export function getPages() {
  return axios.get(V2_URL + "/pages?_fields=author,id,content,excerpt,title,date_gmt,featured_media,meta,slug");
}

export function getPosts() {
  return axios.get(V2_URL + "/posts?_fields=author,id,content,excerpt,title,date_gmt,featured_media,meta,slug");
}

export function getCategories() {
  return axios.get(V2_URL + "/categories")
}
