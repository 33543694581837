import React, { Component } from 'react';
import { pick, merge } from 'lodash'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

import { getBooks, getFAQ, getPages, getPosts, getMedia, getData } from './api';

import PageHeader from './page-components/page-header/page-header';
import PageSectionGroup from './page-components/page-section-group/page-section-group';
import PageSection from './page-components/page-section/page-section';
import PageFooter from './page-components/page-footer/page-footer';

import BookPageSection from './content/book-page-section/book-page-section';
import PreviewBookModal from './content/modals/preview-book/preview-book'

import initData from './content/init.json';

import './App.scss';

class App extends Component {
  constructor(props) {
    super(props);

    const initialData = this.getInitialData(initData);

    this.state = merge({
      title: 'Jakob Ævarsson',
      description: 'Fantasy author',
      menuItems: [],
      sections: [],
      isLoading: true,
      previewId: null,
      mediaLibrary: [],
      users: []
    }, initialData);

    this.loadContent();
  }

  isPinned = (items) => items.some(item => item.meta && item.meta.featured);

  getInitialData(response) {
    const sectionIds = Object.keys(pick(response, ['posts', 'books', 'faq']));

    let sections = sectionIds.map(sectionId => {
      const section = response[sectionId].data;

      switch(sectionId) {
        case 'posts':
          return this.createContentGroup('blog', section.map(resp => this.convertResponseItem(resp)), {
            order: 2,
            featured: this.isPinned(section),
            hideTitle: true,
            showAuthor: true
          });
        case 'books':
          const reviews = response.reviews ? response.reviews.data.map(resp => this.convertReviewResponseItem(resp)) : null;
          return this.createContentGroup(sectionId, section.map(resp => ({
            ...this.convertResponseItem(resp),
            reviews: reviews.filter(r => r.bookId === resp.id.toString())
          })), {
            order: 1,
            featured: this.isPinned(section),
          });
        case 'faq':
          return this.createContentGroup(sectionId, section.map(resp => this.convertResponseItem(resp)), {
            order: 3,
            featured: this.isPinned(section),
            hideTitle: true
          });
        default: return null;
      }
    });

    const pageSections = response.pages.data.map(p => {
      const page = this.convertResponseItem(p);
      return this.createContentGroup(page.title, [page], { ...page.meta, hideTitle: true });
    });

    /*sections.push(this.createContentGroup('about2', response.admins.data.map(admin => ({
      title: admin.name,
      content: admin.description,
      featuredImage: {
        url: admin.avatar_urls["96"],
        size: { width: '300px', height: '300px' }
      }
    })), {
      hideTitle: true,
      order: 4
    }));*/

    sections = sections.concat(pageSections).sort((a, b) => {
      const getOrder = ({ order }) => order || Number.MAX_SAFE_INTEGER;
      return getOrder(a.settings) - getOrder(b.settings);
    });

    return {
      mediaLibrary: this.createMediaLibrary(response.media.data),
      users: response.admins.data.map(({ id, name, url, description, avatar_urls }) => ({ id, name, url, description, images: avatar_urls })),
      menuItems: sections.map(v => v.hook),
      sections: sections,
      isLoading: false,
    };
  }

  loadContent() {
    getData().then(result => {
      this.setState(this.getInitialData(result.data));
    });

    /*getBooks().then(result => {
      this.updateContent(this.createContentGroup('Books', result.data.map(resp => this.convertResponseItem(resp)), {
        order: 1,
        featured: this.isPinned(result.data),
        showAuthor: true
      }));
    });*/

    /*getPages().then(result => {
      result.data.forEach(p => {
        const page = this.convertResponseItem(p);
        this.updateContent(this.createContentGroup(page.title, [page], { ...page.meta, hideTitle: true }));
      });
    });*/
  }

  createMediaLibrary(media) {
    return media.map(m => ({
        id: m.id,
        url: m.guid.rendered,
        slug: m.title.rendered,
        sizes: m.media_details.sizes,
        size: m.media_details.sizes.medium
    }));
  }

  findAuthorById = (id) => {
    const { users } = this.state;

    return users.find(user => user.id === id);
  };

  findMediaById = (id) => {
    const { mediaLibrary } = this.state;

    return mediaLibrary.find(m => m.id === id);
  };

  findMediaBySlug = (slug) => {
    const { mediaLibrary } = this.state;

    return mediaLibrary.find(m => m.slug === slug);
  };

  createContentGroup(name, content, settings = {}) {
    const slug = name.toLowerCase();

    return {
      name: name,
      hook: slug,
      settings: settings,
      content: content
    }
  }

  convertReviewResponseItem = (item) => {
    const { id, content, meta } = item;
    const { bookReviewAuthor, bookReviewScore, bookReviewUrl, bookId } = meta;

    return {
      bookId, id,
      description: content.rendered,
      author: bookReviewAuthor,
      rating: bookReviewScore,
      url: bookReviewUrl
    }
  };

  convertResponseItem = (item) => ({
    id: item.id,
    title: item.title.rendered,
    content: item.content.rendered,
    meta: item.meta,
    author: item.author,
    featuredImageId: item.featured_media,
    date: new Date(item.date_gmt).toLocaleString(),
  });

  handleViewPreview = (asin) => {
    this.setState({
      previewId: asin,
    });
  };

  renderLoadingIndicator() {
    return (
      <div className="loader-container">
        <Spinner animation="border" variant="secondary" />
        <div>Loading content</div>
      </div>
    )
  }

  renderGroupContent(group, item) {
    switch(group.hook) {
      case 'books':
        return (
          <BookPageSection {...item}
            onPreview={this.handleViewPreview}
            featuredImage={ item.featuredImage || this.findMediaById(item.featuredImageId) }
            reviews={ item.reviews }
            meta={item.meta}
            key={ `book-section-${item.title}`} />
        );
      default:
        return (
          <PageSection { ...item }
            key={`page-section-${item.title}`}
            featuredImage={ item.featuredImage || this.findMediaById(item.featuredImageId) }
            showAuthor={ group.settings.showAuthor }
            author={ this.findAuthorById(item.author) }
          />);
    }
  }

  render() {
    const { title, description, menuItems, isLoading, sections, previewId, mediaLibrary } = this.state;

    const featuredSections = sections.filter(f => f.settings.featured);
    const hideContent = previewId !== null;

    return (
      <div className="App">
        <Router>
          <PageHeader title={title} tagline={description} menuItems={menuItems} />
          <div className="App-content">
            { isLoading ? this.renderLoadingIndicator() : null }
            <Switch>
              <Route exact path="/">
                { hideContent ? null : featuredSections.map((f) => (
                  <PageSectionGroup
                    key={`page-section-group-${f.hook}`}
                    title={f.name}
                    slug={f.hook}
                    settings={f.settings}
                    background={ this.findMediaBySlug(`${f.hook}-bg`) }>
                    { f.content.map(c => this.renderGroupContent(f, c, mediaLibrary)) }
                  </PageSectionGroup>
                )) }
              </Route>
              { sections.map((s, i) =>
                <Route exact path={`/${s.hook}` } key={ `route-${s.hook}` }>
                  { hideContent ? null : (<PageSectionGroup
                    title={s.name}
                    slug={s.hook}
                    settings={s.settings}
                    background={ this.findMediaBySlug(`${s.hook}-bg`) }>
                    { s.content.map(c => this.renderGroupContent(s, c, mediaLibrary)) }
                  </PageSectionGroup>) }
                </Route>) }
            </Switch>
          </div>
          { hideContent ? null : <PageFooter title={title} menuItems={menuItems} /> }
        </Router>

        <PreviewBookModal onClose={ () => { this.handleViewPreview(null) } } asin={ previewId } />
      </div>
    );
  }
}

export default App;
