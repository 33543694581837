import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAmazon } from '@fortawesome/free-brands-svg-icons';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import Flag from 'react-flagkit'

import './amazon-action.scss';

export default class AmazonAction extends Component {

  getDomain = countryCode => {
    switch(countryCode) {
      case 'US': return 'com';
      case 'UK': return 'co.uk';
      case 'JP': return 'co.jp';
      default: return countryCode.toLowerCase();
    }
  };

  getFlag = countryCode => {
    switch(countryCode) {
      case 'UK': return 'GB';
      default: return countryCode;
    }
  };

  getAmazonUrl = (countryCode, asin) => {
    return `https://www.amazon.${this.getDomain(countryCode)}/dp/${asin}`
  };

  render() {
    const { asinPaperback, asinKindle, buttonClassModifier, text } = this.props;

    let amazon = {};

    if(asinPaperback) {
      amazon.paperback = {
        countryCodes: ['US', 'UK', 'DE', 'FR', 'ES', 'IT', 'JP', 'CA'],
        asin: asinPaperback
      }
    }

    if(asinKindle) {
      amazon.kindle = {
        countryCodes: ['US', 'UK', 'DE', 'FR', 'ES', 'IT', 'NL', 'JP', 'BR', 'CA', 'MX', 'AU', 'IN'],
        asin: asinKindle
      }
    }

    return (
      <div className="AmazonAction">
        <DropdownButton id="dropdown-basic-button" drop="down"
          variant={ buttonClassModifier }
          title={ (
            <React.Fragment>
              <FontAwesomeIcon icon={ faAmazon } />
              <span>{ text }</span>
            </React.Fragment>) }>
          {
            Object.keys(amazon).map(key => {
              const content = amazon[key];

              return (
                <React.Fragment key={ `amazon-${key}` }>
                  <Dropdown.Header>{ key }</Dropdown.Header>
                  { content.countryCodes.map(code => (
                    <Dropdown.Item href={ this.getAmazonUrl(code, content.asin) } key={ `flag-${code}` }>
                      <Flag country={ this.getFlag(code) } size="21" />
                    </Dropdown.Item>))}
                  <Dropdown.Divider />
                </React.Fragment>)
            })
          }
        </DropdownButton>
      </div>
    )
  }
}
