import React, {Component} from 'react';
import PageContent from '../page-content/page-content';
import SocialMedia from '../../components/social-media/social-media';

import './page-footer.scss';

export default class PageFooter extends Component {
  render() {
    return (
      <div className="PageFooter">
        <PageContent>
          <div className="PageFooter-group-container">
            <h1 className="PageFooter-group-title">Contact</h1>
          </div>
          <div className="PageFooter-group-container">
            <h1 className="PageFooter-group-title">Social Media</h1>
            <SocialMedia
              goodreadsId="20304250.Jakob_varsson"
              facebookId="TheDemonUnderTheMountain"
              instagramId="themanheimchronicles"
              youtubeId="UC2kQzmBX5gQ7TfMO8EtrEQw"
              twitterId="JakobAevarsson"
              type="author" />
          </div>
          <div className="PageFooter-group-container">
            <h1 className="PageFooter-group-title">Page Overview</h1>
            <ul className="PageFooter-menu-container">
              {this.props.menuItems.map(item =>
                <li className="PageFooter-menu-item" key={item}>
                  <a href={"#" + item.hook}>{item}</a>
                </li>)}
            </ul>
          </div>
          <div className="credit">
            <span>Website by <a href="http://www.keendra.com">Jóhanna Landgren</a></span>
            <span>Art by <a href="https://chateaugrief.com/">Jean Guillet</a></span></div>
        </PageContent>
      </div>
    );
  }
}
