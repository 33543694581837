import React, { Component } from 'react';

import PageContent from '../../page-components/page-content/page-content';
import PageView from '../../page-components/page-view/page-view';
import PageSection from '../../page-components/page-section/page-section';
import SocialMedia from '../../components/social-media/social-media';
import BookReview from '../../components/book-review/book-review';

import AmazonAction from '../../components/amazon-action/amazon-action';

import './book-page-section.scss';

export default class BookPageSection extends Component {

  render() {
    const { onPreview, meta, reviews } = this.props;
    const { asinKindle, asinPaperback, goodreadsId, facebookId, instagramId, youtubeId } = meta;

    const asin = { kindle: asinKindle, paperback: asinPaperback };

    return (
      <div className="BookPageSection">
        <PageSection { ...this.props }></PageSection>
        { meta ?
          <PageContent>
            <div className="BookPageSection-actions-container">
              <div className="BookPageSection-actions">
                <button className="action-button" onClick={ () => { onPreview(asinKindle) } }>Preview</button>
                <div className="action-button">
                  <AmazonAction text="Buy" asinKindle={ asinKindle } asinPaperback={ asinPaperback } />
                </div>
              </div>
            </div>
          </PageContent> : null
        }
        { reviews ? <PageContent>
          <div className="CardView">
            { reviews.map((review, i) => (
              <PageView key={ `review-${i}` }>
                <BookReview { ...review } />
              </PageView>)) }
            <PageView key={ 'review-add' }>
              <div className="BookPageSection-addReview">
                <h3>Leave a review</h3>
                Let us know what you thought of the book - leave a review on goodreads or amazon
                <SocialMedia asin={ asin } goodreadsId={ goodreadsId } type="book" />
                <hr />
                <h4>Follow us</h4>
                <SocialMedia facebookId={ facebookId } instagramId={ instagramId } youtubeId={ youtubeId } type="book" />
              </div>
            </PageView>
          </div>
        </PageContent> : null
        }
      </div>
    );
  }
}
