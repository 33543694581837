import React, {Component} from 'react';
import { Modal } from 'react-bootstrap';

import './preview-book.scss';

export default class PreviewBookModal extends Component {
  render() {
    const { asin, onClose } = this.props;

    return (
      <div className="PreviewBookModal">
        <Modal
          show={ asin !== null }
          onHide={ onClose }
          dialogClassName="modal-90w"
          aria-labelledby="book-preview-modal-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="book-preview-modal-title">
              Book preview
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <figure className="wp-block-embed-amazon-kindle wp-block-embed is-type-rich is-provider-amazon">
              <div className="wp-block-embed__wrapper">
                <iframe title="The Demon Under the Mountain" type="text/html" className="book-preview" width="100%" height="600" frameborder="0" allowfullscreen="" src="https://read.amazon.com/kp/card?preview=inline&amp;linkCode=kpd&amp;ref_=k4w_oembed_20rvFfKHn0kNkZ&amp;asin=B088FVT4CJ&amp;tag=kpembed-20"></iframe>
              </div>
            </figure>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
