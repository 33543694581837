import React, {Component} from 'react';
import { Link, withRouter } from 'react-router-dom';
import PageContent from '../page-content/page-content';
import SocialMedia from '../../components/social-media/social-media';

import './page-header.scss';

class PageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = { showMenu: false }
  }

  render() {
    const { showMenu } = this.state;
    const { location } = this.props;
    const path = location.pathname.replace('/', '');

    return (
      <header className="PageHeader">
        <PageContent>
          <div className="PageHeader-logo-container"></div>
          <div className="PageHeader-title-container">
            <Link to="/">
              <h1 className="PageHeader-title">{
                this.props.title.split(" ").map(title => <span key={ `page-title-${title}` }>{title} </span> )
              }</h1>
              <h2 className="PageHeader-title-tagline">{this.props.tagline}</h2>
            </Link>
            <div className={ `${ showMenu ? "open" : "" }` }>
              <SocialMedia
                goodreadsId="20304250.Jakob_varsson"
                facebookId="TheDemonUnderTheMountain"
                instagramId="themanheimchronicles"
                youtubeId="UC2kQzmBX5gQ7TfMO8EtrEQw"
                twitterId="JakobAevarsson"
                type="author" />
            </div>
          </div>
          <div className="PageHeader-menu-toggler" onClick={ () => { this.setState({ showMenu: !showMenu }); }}></div>
          <ul className={ `PageHeader-menu-container ${ showMenu ? "open" : "" }` }>
            {this.props.menuItems.map((item, index) => (
              <li className={ `PageHeader-menu-item ${path===item ? 'active' : ''}` } key={item + index}>
                <Link to={ `/${item}` } onClick={ () => { this.setState({ showMenu: false }); }}>{item}</Link>
              </li>))}
          </ul>
        </PageContent>
      </header>)
  }
}

export default withRouter(PageHeader)