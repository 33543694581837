import React, {Component} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube, faFacebook, faTwitter, faInstagram, faGoodreads } from "@fortawesome/free-brands-svg-icons";
import AmazonAction from '../../components/amazon-action/amazon-action';

import './social-media.scss';

const TYPE_AUTHOR = 'author';
const TYPE_BOOK = 'book';

export default class SocialMedia extends Component {

  getUrl = (id, platform) => {
    const { type } = this.props;

    switch (platform) {
      case 'facebook': return `https://www.facebook.com/${id}`;
      case 'instagram': return `https://www.instagram.com/${id}`;
      case 'twitter': return `https://www.twitter.com/${id}`;
      case 'youtube': return `https://www.youtube.com/channel/${id}`;
      case 'goodreads': return `https://www.goodreads.com/${type || TYPE_AUTHOR}/show/${id}`;
      default: return null;
    }
  };

  getIcon = (platform) => {
    switch (platform) {
      case 'facebook': return faFacebook;
      case 'instagram': return faInstagram;
      case 'twitter': return faTwitter;
      case 'youtube': return faYoutube;
      case 'goodreads': return faGoodreads;
      default: return null;
    }
  };

  getBadge = (id, platform) => {
    return <a href={ this.getUrl(id, platform) } className={ `${platform} social` }>
      <FontAwesomeIcon icon={ this.getIcon(platform) } />
    </a>
  };

  render() {
    const { facebookId, instagramId, youtubeId, twitterId, goodreadsId, asin } = this.props;

    return (
      <div className="SocialMedia">
        { asin ? <AmazonAction asinKindle={ asin.kindle } asinPaperback={ asin.paperback } buttonClassModifier="link" /> : null }
        { goodreadsId ? this.getBadge(goodreadsId, 'goodreads') : null }
        { facebookId ? this.getBadge(facebookId, 'facebook') : null }
        { instagramId ? this.getBadge(instagramId, 'instagram') : null }
        { youtubeId ? this.getBadge(youtubeId, 'youtube') : null }
        { twitterId ? this.getBadge(twitterId, 'twitter') : null }
      </div>
    );
  }
}
