import React, {Component} from 'react';
import './page-view.scss';

export default class PageView extends Component {
  render() {
    const { children } = this.props;

    return (
      <div className="PageView">
        { children }
      </div>
    );
  }
}
