import React, {Component} from 'react';
import PageContent from '../page-content/page-content';
import PageView from '../page-view/page-view';
import AuthorInfo from '../../components/author-info/author-info';

import './page-section.scss';

export default class PageSection extends Component {

  getModifiers = () => {
    const { background, hasNeighbours } = this.props;

    let modifiers = [];

    if(background) {
      modifiers.push("PageSection-with-background");
    }

    if(hasNeighbours) {
      modifiers.push("has-more-neighbours");
    }

    return modifiers.join(" ")
  };

  render() {
    const { background, content, title, featuredImage, showAuthor, author, date } = this.props;

    const getReplaceAllRegExp = (tag) => new RegExp(tag, 'g');
    const imageSize = featuredImage ? featuredImage.size : null;

    return (
      <div className={ `PageSection ${this.getModifiers()}` }>
        { background &&
        <div className="page-section-background"
             style={{backgroundImage: "url(" + background.url + ")"}}>
        </div>}
        <PageContent>
          <PageView>
            { // eslint-disable-next-line
              featuredImage && <img src={ featuredImage.url }
                                     className="featured-image"
                                     style={{
                                       float: "left",
                                       width: imageSize.width,
                                       height: imageSize.height
                                     }} /> }
              <div className="PageSection-body">
                { title && <h2 dangerouslySetInnerHTML={{__html: title}}></h2> }
                { showAuthor && <AuthorInfo author={ author } date={ date } /> }
                <span className="page-container-content-body"
                     dangerouslySetInnerHTML={{__html: (content)
                       .replace(getReplaceAllRegExp("<p>"), "<p><span>")
                       .replace(getReplaceAllRegExp("</p>"), "</span></p>")}}>
                </span>
              </div>
          </PageView>
        </PageContent>
      </div>
    );
  }
}
